import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface UiState {
  orgName: string;
}

const initialState = {
  orgName: '',
} as UiState;

const uiSlice = createSlice({
  name: 'ui',
  initialState: initialState,
  reducers: {
    setOrgName: (state, action: PayloadAction<string>) => {
      state.orgName = action.payload;
    },
  },
});

export const { setOrgName } = uiSlice.actions;

export default uiSlice.reducer;
