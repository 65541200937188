import { combineReducers, configureStore } from '@reduxjs/toolkit';

import themeConfigSlice from '@/store/themeConfigSlice';

import { uiReducer } from './ui';

const rootReducer = combineReducers({
  themeConfig: themeConfigSlice,
  ui: uiReducer,
});

export default configureStore({
  reducer: rootReducer,
});

export type IRootState = ReturnType<typeof rootReducer>;
